<template>
  <div class="moment-images-control__item">
    <div class="moment-images-control__left">
      <i class="fa-regular fa-grip-dots-vertical" />
      <div class="moment-images-control__image">
        <img :src="image.url" alt="">
        <i v-if="!disabled" class="remove-btn fa-light fa-circle-xmark" @click="removeImage(image)" />

        <div class="collage-actions" v-if="collageState.state !== COLLAGE_STATES.FINISHED">
          <template v-if="collageState.state === COLLAGE_STATES.INITIAL || collageState.orientation === image.orientation">
            <button
              type="button"
              :disabled="collageState.imgs[0] && !image.collageBefore || image.collageAfter"
              :class="['btn', { 'btn-primary': image.collageBefore, 'btn-link': collageState.imgs[0] && !image.collageBefore || image.collageAfter }]"
              @click.prevent="$emit('updateImage', { collageBefore: !image.collageBefore })">
              Before
            </button>

            <button
              type="button"
              :disabled="collageState.imgs[1] && !image.collageAfter || image.collageBefore"
              :class="['btn', { 'btn-primary': image.collageAfter, 'btn-link': collageState.imgs[1] && !image.collageAfter || image.collageBefore }]"
              @click.prevent="$emit('updateImage', { collageAfter: !image.collageAfter })">
              After
            </button>
          </template>

          <small v-else>
            <i class="fa-regular fa-info-circle" />
            Can’t be collaged
          </small>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-start gap-2 flex-1">
      <div class="form-field flex-1">
        <textarea
          v-model="description"
          :class="{ 'border-danger': errorText, processing: isRequest }"
          :disabled="disabled || isRequest"
          :readonly="isRequest"
          @input="onTextareaChange" />
        <small v-if="errorText" class="text-danger">{{ errorText }}</small>
        <small v-if="successText" class="color-green">{{ successText }}</small>
      </div>
      <div v-if="!disabled" class="moment-images-control__ai">
        <button type="button" :class="['ai-btn', { processing: isRequest }]" :disabled="isRequest" @click="generateDescription">
          <template v-if="isRequest">
            <i class="fa-solid fa-spinner-scale fa-spin-pulse" />
            Generating
          </template>
          <template v-else>
            <i class="far fa-sparkles" />
            Generate<br>description
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import toastr from 'toastr'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { COLLAGE_STATES } from '../helpers'

export default {
  props: {
    image: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
    aiMessages: { type: Array, default: () => []},
    collageState: { type: Object, required: true }
  },
  data() {
    return {
      description: this.image.description || '',
      isRequest: false,
      jid: null,
      errorText: '',
      successText: ''
    }
  },
  created() {
    this.memText = ''
    this.COLLAGE_STATES = COLLAGE_STATES
  },
  methods: {
    async generateDescription() {
      this.isRequest = true
      this.memText = this.description
      this.description = 'Description is being generated'

      this.$emit('updateImage', { isDescriptionGenerating: true })

      let imageId = this.image.id
      if (!imageId) {
        imageId = await this.createImage(this.image)
        this.$emit('updateImage', { id: imageId })
      }

      axios.post(
        '/gb/ajax/moments/describe_image',
        { imageId },
        axiosTransform
      ).then((res) => {
        this.jid = res.data.jid
        this.handleAIMessage()
      }).catch(() => {
        this.description = this.memText
        toastr.error(DEFAULT_ERROR_MESSAGE)
        this.$emit('updateImage', { isDescriptionGenerating: false })
      })
    },
    async createImage(image) {
      const response = await fetch(image.url, { mode: 'no-cors' })
      const data = await response.blob()
      const metadata = { type: data.type }
      const ext = data.type.split('/')[1]

      const imgFile = new File([data], `${(new Date()).getTime()}.${ext}`, metadata)

      return axios.post('/api/images', { file: imgFile }, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => res.data.id)
        .catch(() => {
          this.description = this.memText
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    },
    onTextareaChange(e) {
      this.errorText = ''
      this.successText = ''
      this.$emit('updateImage', { description: e.target.value, isDescriptionGenerating: false })
    },
    handleAIMessage() {
      if (!this.jid) return

      this.aiMessages.forEach((msg) => {
        if (msg.jid !== this.jid) return

        this.isRequest = false
        this.errorText = ''
        this.successText = ''
        if (msg.failed) {
          this.description = this.memText
          this.errorText = msg.result
          toastr.error(this.errorText)
          this.$emit('updateImage', { isDescriptionGenerating: false })
        } else {
          this.description = msg.result
          this.successText = 'The description has been generated.'
          toastr.success(this.successText)
          this.$emit('updateImage', { description: this.description, isDescriptionGenerating: false })
        }

        this.jid = null
      })
    },
    removeImage(image) {
      if (!this.isRequest) {
        this.$emit('removeImage', image)
        return
      }

      swal({
        text: 'The image description is currently being generated, please wait.',
        icon: 'warning',
        buttons: [null, 'OK'],
        dangerMode: true
      })
    }
  },
  watch: {
    aiMessages() {
      this.handleAIMessage()
    }
  }
}
</script>
