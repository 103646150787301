<template>
  <div>
    <div v-if="allowNewBusinesses" class="no-business text-center">
      <div class="no-business__image">
        <img :src="require('mv/add_business_image.svg')" alt="Papers with a plus sign">
      </div>
      <a class="btn btn-primary no-business__connect" href="/gb/businesses/new">
        <i class="far fa-plus fa-fw" />
        Connect your business
      </a>
      <span class="no-business__text">and<br>start making hyper-local content.</span>
    </div>
    <p v-else>
      Unfortunately, your organization is not authorized to add new businesses.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    allowNewBusinesses: {
      type: Boolean
    }
  }
}
</script>
