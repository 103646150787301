<template>
  <div>
    <CategoriesForm :categories="categories" :mobile-ajax-categories-path="mobileAjaxCategoriesPath" :business-id="businessId" />
    <PageLinksForm v-if="fbEnabled"
      :mobile-business-path="mobileBusinessPath"
      :pages-path="fbPagesPath"
      :oauth-url="facebookOauthUrl" />
    <PageLinksForm v-if="igEnabled"
      :mobile-business-path="mobileBusinessPath"
      :pages-path="igAccountsPath"
      :oauth-url="instagramOauthUrl"
      mobile-business-field-name="instagram_account_id"
      page-source-name="Instagram">
      <template #additionalInfo>
        <p class="mb-4 pb-2 text-muted">Please note that any media content you share must comply with the Instagram guidelines.</p>
      </template>
      <template #oauthButtonIcon>
        <i class="fa-brands fa-square-instagram mr-2" />
      </template>
    </PageLinksForm>
    <PageLinksForm v-if="ytEnabled"
      :mobile-business-path="mobileBusinessPath"
      :pages-path="ytChannelsPath"
      :oauth-url="youtubeOauthUrl"
      mobile-business-field-name="youtube_channel_id"
      page-source-name="YouTube">
      <template #additionalInfo>
        <p class="mb-4 pb-2 text-muted">Please note that any media content you share must comply with the YouTube guidelines.</p>
      </template>
      <template #oauthButtonIcon>
        <i class="fa-brands fa-square-youtube mr-2" />
      </template>
    </PageLinksForm>
    <DefaultValuesForm :publish-video-by-default="publishVideoByDefault" :mobile-business-path="mobileBusinessPath" />
    <SubscriptionForm
      :subscription-cancelled="subscriptionCancelled"
      :free-subscription="freeSubscription"
      :free-subscription-available="freeSubscriptionAvailable"
      :mobile-business-path="mobileBusinessPath" />
    <WebhookForm
      :webhook-url="webhookUrl"
      :webhook-bearer-token="webhookBearerToken"
      :zapier-webhook-url="zapierWebhookUrl"
      :mobile-business-path="mobileBusinessPath" />
    <MomentDestinationsForm
      :publish-facebook="publishFacebook"
      :publish-instagram="publishInstagram"
      :publish-youtube="publishYoutube"
      :publish-gmb="publishGmb"
      :publish-webhook="publishWebhook"
      :mobile-business-path="mobileBusinessPath"
      :fb-enabled="fbEnabled"
      :yt-enabled="ytEnabled"
      :ig-enabled="igEnabled" />
  </div>
</template>

<script>
import CategoriesForm from './settings/categories_form'
import PageLinksForm from './settings/page_links_form'
import DefaultValuesForm from './settings/default_values_form'
import WebhookForm from './settings/webhook_form'
import SubscriptionForm from './settings/subscription_form'
import MomentDestinationsForm from './settings/moment_destinations_form'

export default {
  components: {
    CategoriesForm,
    PageLinksForm,
    DefaultValuesForm,
    WebhookForm,
    SubscriptionForm,
    MomentDestinationsForm
  },
  props: {
    categories: { type: Array, required: true },
    webhookUrl: { type: String, default: '' },
    webhookBearerToken: { type: String, default: '' },
    zapierWebhookUrl: { type: String, default: '' },
    mobileBusinessPath: { type: String, default: '' },
    mobileAjaxCategoriesPath: { type: String, default: '' },
    businessId: { type: String, required: true },
    subscriptionCancelled: { type: Boolean, default: false },
    freeSubscription: { type: Boolean, default: false },
    freeSubscriptionAvailable: { type: Boolean, default: false },
    gbWidgetUrl: { type: String, required: true },
    publishVideoByDefault: { type: Boolean, default: false },
    fbPagesPath: { type: String, default: '' },
    igAccountsPath: { type: String, default: '' },
    ytChannelsPath: { type: String, default: '' },
    facebookOauthUrl: { type: String, default: '#' },
    instagramOauthUrl: { type: String, default: '#' },
    youtubeOauthUrl: { type: String, default: '#' },
    fbEnabled: { type: Boolean, required: true },
    igEnabled: { type: Boolean, required: true },
    ytEnabled: { type: Boolean, required: true },
    publishFacebook: { type: Boolean, required: false, default: false },
    publishGmb: { type: Boolean, required: false, default: false },
    publishInstagram: { type: Boolean, required: false, default: false },
    publishYoutube: { type: Boolean, required: false, default: false },
    publishWebhook: { type: Boolean, required: false, default: false }
  },
  computed: {
    appHelpLink() {
      return Styxie.WLC.appCodename === 'lv' ?
        'https://help.localviking.com/en/articles/4758104-geobooster-js-widget' :
        'https://help.localbrandmanager.com/en/articles/6797699-geobooster-js-widget'
    }
  }
}
</script>
