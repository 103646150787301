<template>
  <div class="d-flex align-items-end gap-6">
    <CustomInput
      v-model.trim="search"
      class="w-35"
      placeholder="All"
      label="Locations" />
    <CustomSelect
      v-model="rating"
      class="w-35"
      label="Rating"
      placeholder="All"
      :allow-empty="true"
      :values="ratings" />
    <CustomSelect
      v-model="response"
      class="w-35"
      label="Response"
      placeholder="Any"
      :allow-empty="true"
      :values="responseOptions" />
    <button class="btn btn-pretender px-0 border-0 gap-2"
      :class="['w-15', { 'text-muted': !anyFiltersSelected }]"
      :disabled="!anyFiltersSelected"
      @click="clearFilters">
      <i class="far fa-eraser" aria-hidden="true" />
      Clear filters
    </button>
  </div>
</template>
<script>
import CustomInput from 'vue_widgets/components/custom_input'
import CustomSelect from 'vue_widgets/components/custom_select'

export default {
  components: {
    CustomInput, CustomSelect
  },
  props: {
    value: { type: Object, required: true }
  },
  data: () => ({
    search: '',
    response: '',
    rating: '',
    ratings: [
      { text: '⭐️', value: 1 },
      { text: '⭐️⭐️', value: 2 },
      { text: '⭐️⭐️⭐️', value: 3 },
      { text: '⭐️⭐️⭐️⭐️', value: 4 },
      { text: '⭐️⭐️⭐️⭐️⭐️', value: 5 }
    ],
    responseOptions: [
      { text: 'With response', value: 'true' },
      { text: 'No response', value: 'false' }
    ]
  }),
  computed: {
    anyFiltersSelected() {
      return this.search !== '' || this.response !== '' || this.rating !== ''
    },
    selectedFilters() {
      const { search, response, rating } = this
      return { search, response, rating }
    }
  },
  methods: {
    clearFilters() {
      this.search = ''
      this.rating = ''
      this.response = ''
    }
  },
  watch: {
    selectedFilters: {
      handler() {
        this.$emit('input', this.selectedFilters)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
