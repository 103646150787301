import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import CheckinIndex from '../../vue_widgets/mobile/checkins/index'
import checkinForm from '../../vue_widgets/mobile/checkins/form/index'

const IMAGE_ORIENTATION = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL'
}

function readImage(img) {
  return new Promise((resolve) => {
    const imgTag = new Image()

    imgTag.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = imgTag
      const orientation = width > height ? IMAGE_ORIENTATION.HORIZONTAL : IMAGE_ORIENTATION.VERTICAL
      resolve({
        file: null, imgTag, collageBefore: false, collageAfter: false, orientation, ...img
      })
    }

    imgTag.src = img.url
  })
}

Styxie.Initializers.MobileCheckins = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-checkin-index'),
        render: (h) => h(CheckinIndex, { props: camelCaseKeys(params) })
      })
    })
  },
  new: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-checkin-form'),
        render: (h) => h(checkinForm, { props: camelCaseKeys(params) })
      })
    })
  },
  edit: async(params) => {
    const props = camelCaseKeys(params)
    props.checkin.images = await Promise.all(props.checkin.images.map((img) => readImage(img)))

    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-checkin-form'),
        render: (h) => h(checkinForm, { props })
      })
    })
  }
}
