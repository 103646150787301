<template>
  <div class="mobile-reviews-dashboard">
    <h2 class="h3 mb-3 font-weight-semi-bold text-dark">Reviews</h2>
    <ReviewsFilters
      v-model="filters"
      class="w-100 mb-4" />
    <ReviewCard
      v-for="review, index in reviews"
      :review="review"
      :key="index" />
    <Pagination
      :page="currentPage"
      :per-page="perPage"
      :total-count="totalCount"
      @updatePage="updatePage($event, true)" />
  </div>
</template>

<script>
import snakecaseKeys from 'snakecase-keys'
import Pagination from 'vue_widgets/components/pagination'
import ReviewsFilters from './filters'
import ReviewCard from './review_card'

export default {
  components: { ReviewsFilters, ReviewCard, Pagination },
  data() {
    return {
      filters: { search: '', response: '', rating: '' },
      reviews: [],
      totalCount: 0,
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    updatePage(page) {
      this.currentPage = page
      this.$nextTick(() => { this.getReviews() })
    },
    getReviews() {
      return axios.get(
        '/gb/ajax/businesses/reviews',
        {
          params: {
            ...this.filters,
            perPage: this.perPage,
            page: this.currentPage
          },
          paramsSerializer(json) { return qs.stringify(snakecaseKeys(json, { deep: true }), { arrayFormat: 'brackets' }) }
        }
      ).then((res) => {
        this.reviews = res.data.reviews
        this.totalCount = res.data.total_reviews
      })
    }
  },
  watch: {
    filters: {
      handler(old, newv) {
        if (JSON.stringify(old) === JSON.stringify(newv)) return
        clearTimeout(this.timeout)
        this.currentPage = 1
        this.timeout = setTimeout(() => {
          this.getReviews()
        }, 500)
      },
      deep: true
    }
  },
  created() {
    this.getReviews()
  }
}
</script>
