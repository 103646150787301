<template>
  <div>
    <div v-if="checkinIsApproved" class="moment-statuses">
      <div v-for="status in statuses"
        class="moment-statuses__item"
        :class="`moment-statuses__item--${status.status}`"
        :id="status.key"
        :key="status.key">
        <i class="moment-statuses__icon" :class="status.icon" />
        <div v-if="status.warning" class="moment-statuses__warning">
          <i class="fa-regular fa-warning text-warning" />
        </div>
        <a v-if="status.link" :href="status.link" target="_blank" class="moment-statuses__link">
          <i class="fa-regular fa-link" />
          <span>Link to post</span>
        </a>
        <BTooltip v-if="status.text" :target="status.key" :title="status.text" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  FB_STATUSES
} from './helpers'

export default {
  props: {
    checkin: {
      type: Object,
      required: true
    }
  },
  methods: {
    webhookStatus(status, deliveredAt) {
      if (status === 'skipped') return 'skipped'
      if (deliveredAt) return 'delivered'

      return 'pending'
    },
    facebookStatus(status) {
      return FB_STATUSES[status]
    }
  },
  computed: {
    checkinIsApproved() {
      return this.checkin.aasmState === 'approved'
    },
    gbpPostStatus() {
      const { checkin } = this
      if (checkin.localPostStatus) return checkin.localPostStatus
      if (checkin.business.disconnected) return 'disconnected'
      if (checkin.postingError) return 'failed'

      return 'pending'
    },
    facebookPostPublished() {
      const { checkin } = this
      return checkin.facebookPostingStatus === 'published' && checkin.facebookPostUrl
    },
    statuses() {
      const { checkin } = this
      const checkinId = checkin.obfuscatedId
      const statusKey = (keyName) => `${checkinId}-${keyName}`

      const webhookStatus = this.webhookStatus(checkin.zapierWebhookStatus, checkin.zapierWebhookDeliveredAt)

      const formattedStatuses = [
        {
          key: statusKey('gbp'),
          status: this.gbpPostStatus,
          icon: 'fa-brands fa-google',
          link: this.checkin.localPostSearchUrl,
          text: this.gbpPostStatus === 'disconnected' ? 'skipped' : this.gbpPostStatus
        },
        {
          key: statusKey('webhook'),
          status: webhookStatus,
          icon: 'fa-solid fa-webhook',
          text: webhookStatus
        }
      ]

      if (checkin.fbEnabled && checkin.facebookPostingStatus) {
        formattedStatuses.push({
          key: statusKey('facebook'),
          status: checkin.facebookPostingStatus,
          icon: 'fa-brands fa-facebook',
          link: checkin.facebookPostUrl,
          text: checkin.facebookPostingError ?? checkin.facebookPostingStatus
        })
      }

      if (checkin.igEnabled && checkin.instagramPostingStatus) {
        let tooltipText = checkin.instagramPostingError ?? checkin.instagramPostingStatus

        if (checkin.instagramPartlyPublished) {
          tooltipText = 'Failed to post some of the media'
        }

        formattedStatuses.push({
          key: statusKey('instagram'),
          status: checkin.instagramPostingStatus,
          icon: 'fa-brands fa-instagram',
          link: checkin.instagramPostUrl,
          text: tooltipText,
          warning: checkin.instagramPartlyPublished
        })
      }

      if (checkin.ytEnabled && checkin.youtubePostingStatus) {
        formattedStatuses.push({
          key: statusKey('youtube'),
          status: checkin.youtubePostingStatus,
          icon: 'fa-brands fa-youtube',
          link: checkin.youtubePostUrl,
          text: checkin.youtubePostingError ?? checkin.youtubePostingStatus
        })
      }

      return formattedStatuses
    }
  }
}
</script>
