<template>
  <div class="mt-3">
    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="table table-striped table-light">
      <template v-for="column in columns" #[`head:${column.key}`]>
        <span class="bold">{{ column.title }}</span>
        <small>{{ column.additionalInfo }}</small>
      </template>

      <template v-for="column in columns" #[`cell:${column.key}`]="{ value }">
        <span v-if="value?.part" class="bold">{{ value?.part }}</span>
        <span v-if="value?.percentage">{{ value?.percentage }}</span>
        <p v-else class="m-0">{{ value }}</p>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import toastr from 'toastr'

export default {
  name: 'StatsTable',
  data() {
    return {
      columns: [
        { title: 'Reasons', key: 'reasons' },
        { title: '30 Initiated', key: 'thirty_initiated', additionalInfo: '(total percentage)' },
        { title: '30 Canceled', key: 'thirty_canceled', additionalInfo: '(% from initiated)' },
        { title: '60 Initiated', key: 'sixty_initiated', additionalInfo: '(total percentage)' },
        { title: '60 Canceled', key: 'sixty_canceled', additionalInfo: '(% from initiated)' },
        { title: '90 Initiated', key: 'ninety_initiated', additionalInfo: '(total percentage)' },
        { title: '90 Canceled', key: 'ninety_canceled', additionalInfo: '(% from initiated)' },
        { title: 'Year Initiated', key: 'yearly_initiated', additionalInfo: '(total percentage)' },
        { title: 'Year Canceled', key: 'yearly_canceled', additionalInfo: '(% from initiated)' }
      ],

      reasons: [
        'bugs',
        'functionality',
        'cost',
        'support',
        'complicated',
        'time',
        'closed',
        'changing_platform',
        'other',
        'Total amount of unsubscribers'
      ]
    }
  },

  created() {
    this.timePeriods = ['thirty_day', 'sixty_day', 'ninety_day', 'yearly']
  },

  methods: {
    tableSource() {
      return axios
        .get('/api/admin/subscription_cancelations/stats')
        .then((response) => {
          const data = response.data.stat_table_data

          const totalRow = this.getTotalRow(data)

          const renderData = this.reasons.slice(0, -1).map((reason) => {
            const formattedRow = { reasons: reason }

            this.timePeriods.forEach((timePeriod) => {
              const [timePeriodName] = timePeriod.split('_')
              const initiatedRowKey = `${timePeriodName}_initiated`

              const periodInitiated = this.getCancellationCount(data, timePeriod, reason, 'initiated')
              const periodCanceled = this.getCancellationCount(data, timePeriod, reason, 'canceled')

              formattedRow[initiatedRowKey] = this.formatTableValue(periodInitiated, totalRow[initiatedRowKey].part)
              formattedRow[`${timePeriodName}_canceled`] = this.formatTableValue(periodCanceled, periodInitiated)
            })

            return formattedRow
          })

          renderData.push(totalRow)

          return renderData
        })
        .catch((error) => { toastr.error(error) })
    },

    getCancellationCount(data, period, reason, type) {
      return data[period]?.table_data.find((item) => item.reasons === reason)?.[type] || 0
    },

    getTotalRow(data) {
      const totalRow = { reasons: 'Total amount of unsubscribers' }

      this.timePeriods.forEach((timePeriod) => {
        const [timePeriodName] = timePeriod.split('_')

        let totalInitiated = 0
        let totalCanceled = 0

        this.reasons.forEach((reason) => {
          totalInitiated += this.getCancellationCount(data, timePeriod, reason, 'initiated')
          totalCanceled += this.getCancellationCount(data, timePeriod, reason, 'canceled')
        })

        totalRow[`${timePeriodName}_initiated`] = this.formatTableValue(totalInitiated)
        totalRow[`${timePeriodName}_canceled`] = this.formatTableValue(totalCanceled, totalInitiated)
      })

      return totalRow
    },

    formatTableValue(part, total = undefined) {
      if (part === 0 || total === 0) return null

      if (!total) {
        return {
          part,
          percentage: '(100%)'
        }
      }

      const percentage = this.calculatePercentage(total, part)
      return {
        part,
        percentage: `(${percentage} %)`
      }
    },

    calculatePercentage(total, part) {
      if (total === 0) return 0
      return ((part / total) * 100).toFixed(1)
    }
  }
}
</script>
