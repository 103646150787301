<template>
  <div class="mv-checkin-state--container d-flex align-items-start gap-4">
    <div class="d-flex flex-column gap-1">
      <div class="mv-checkin-state approved">
        <i class="far fa-shield-check" />
        Approved
      </div>
      <span class="font-size-14">{{ checkin.approvedAt }}</span>
    </div>
    <div class="d-flex align-items-center gap-2 ml-auto">
      <div :class="stateClasses(checkin.localPostObfuscatedId)">
        <i :class="checkinStateIcon(checkin.localPostObfuscatedId)" id="gmb-state" />
        <BTooltip target="gmb-state">
          <div class="text-dark text-capitalize">
            {{ gmbPostStatus(checkin) }}
          </div>
        </BTooltip>
        GMB
      </div>
      <div v-if="checkin.zapierWebhookStatus" :class="stateClasses(checkin.zapierWebhookDeliveredAt)">
        <i :class="checkinStateIcon(checkin.zapierWebhookDeliveredAt)" id="zapier-state" />
        <BTooltip target="zapier-state">
          <div class="text-dark text-capitalize">
            {{ webhookStatusDescription(checkin.zapierWebhookStatus, checkin.zapierWebhookDeliveredAt) }}
          </div>
        </BTooltip>
        Webhook
      </div>
      <div v-if="checkin.webhookStatus" :class="stateClasses(checkin.webhookDeliveredAt)">
        <i :class="checkinStateIcon(checkin.webhookDeliveredAt)" id="wordpress-state" />
        <BTooltip target="wordpress-state">
          <div class="text-dark text-capitalize">
            {{ webhookStatusDescription(checkin.webhookStatus, checkin.webhookDeliveredAt) }}
          </div>
        </BTooltip>
        WordPress
      </div>
      <div v-if="checkin.fbEnabled && checkin.facebookPostingStatus"
        :class="stateClasses(checkin.facebookPostingStatus === 'published')">
        <i :class="checkinStateIcon(checkin.facebookPostingStatus === 'published')" id="facebook-state" />
        <BTooltip target="facebook-state">
          <div class="text-dark text-capitalize">
            <template v-if="checkin.facebookPostingError">{{ checkin.facebookPostingError }}</template>
            <template v-else>{{ facebookStatusDescription(checkin.facebookPostingStatus) }}</template>
          </div>
        </BTooltip>
        Facebook
      </div>
      <div v-if="checkin.igEnabled && checkin.instagramPostingStatus" :class="stateClasses(instagramPublishedCorrectly)">
        <i :class="checkinStateIcon(instagramPublishedCorrectly)" id="instagram-state" />
        <BTooltip target="instagram-state">
          <div class="text-dark text-capitalize">
            <template v-if="checkin.instagramPostingError">{{ checkin.instagramPostingError }}</template>
            <template v-else-if="checkin.instagramPartlyPublished">
              Failed to post some of the media
            </template>
            <template v-else>{{ facebookStatusDescription(checkin.instagramPostingStatus) }}</template>
          </div>
        </BTooltip>
        Instagram
      </div>
      <div v-if="checkin.ytEnabled && checkin.youtubePostingStatus"
        :class="stateClasses(checkin.youtubePostingStatus === 'published')">
        <i :class="checkinStateIcon(checkin.youtubePostingStatus === 'published')" id="youtube-state" />
        <BTooltip target="youtube-state">
          <div class="text-dark text-capitalize">
            <template v-if="checkin.youtubePostingError">{{ checkin.youtubePostingError }}</template>
            <template v-else>{{ facebookStatusDescription(checkin.youtubePostingStatus) }}</template>
          </div>
        </BTooltip>
        YouTube
      </div>
    </div>
  </div>
</template>

<script>
import { FB_STATUSES } from '../helpers'

export default {
  props: {
    checkin: { type: Object, required: true }
  },
  computed: {
    instagramPublishedCorrectly() {
      return this.checkin.instagramPostingStatus === 'published' && !this.checkin.instagramPartlyPublished
    }
  },
  methods: {
    gmbPostStatus(checkin) {
      if (checkin.localPostStatus) return checkin.localPostStatus
      if (checkin.business.disconnected) return 'Skipped'
      if (checkin.postingError) return 'failed'

      return 'pending'
    },
    webhookStatusDescription(status, deliveredAt) {
      if (status === 'skipped') return 'skipped'
      if (deliveredAt) return 'delivered'

      return 'pending'
    },
    facebookStatusDescription(status) {
      return FB_STATUSES[status]
    },
    stateClasses(isEnabled) {
      return ['mv-checkin-state', { enabled: isEnabled, pending: !isEnabled }]
    },
    checkinStateIcon(isEnabled) {
      return `far fa-${isEnabled ? 'check' : 'exclamation'}-circle`
    },
    facebookPostPresent(checkin) {
      return checkin.facebookPostingStatus !== 'skipped'
    },
    instagramPostPresent(checkin) {
      return checkin.instagramPostingStatus !== 'skipped'
    }
  }
}
</script>
