<template>
  <div class="mv-grid">
    <template v-if="businesses?.length">
      <div class="narrow-container">
        <div v-if="checkins.length === 0 && !isRequestSent">
          <span v-if="filtersAreApplied">No moments found with these filters.</span>
          <NoMoments v-else :ios-app-url="iosAppUrl" :android-app-url="androidAppUrl" />
        </div>
        <div v-else-if="isRequestSent" class="d-flex justify-content-center align-items-center h1 h-50">
          <i class="far fa-spinner fa-spin" />
        </div>
        <template v-else>
          <MomentElement v-for="checkin in checkins"
            :key="checkin.obfuscatedId"
            :checkin="checkin"
            @updateMomentList="getCheckins"
            @openPreview="openPreview" />
          <Pagination :page="currentPage" :per-page="perPage" :total-count="totalCount" @updatePage="updatePage" />
        </template>
      </div>
      <div class="sub-container">
        <MVFilter
          :businesses-list="businesses"
          :employees-list="employees"
          :categories-list="categories"
          @onChange="setFilter"
          @getCheckins="getCheckins" />
      </div>
      <PreviewImages v-if="mediaIndex !== null"
        :media="mediaSet"
        :current-media-index="mediaIndex"
        @close="closeImage" />
    </template>
    <NoBusiness v-else :allow-new-businesses="allowNewBusinesses" />
  </div>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import axiosTransform from '../../../common/axios'
import MomentElement from './moment_element'
import MVFilter from './filter'
import Pagination from '../../components/pagination'
import PreviewImages from '../../gallery/preview_images'
import NoMoments from './no_moments'
import NoBusiness from './no_business'
import { BASE_AJAX_URL } from './helpers'

export default {
  components: {
    MomentElement, Pagination, MVFilter, PreviewImages, NoMoments, NoBusiness
  },
  props: {
    businesses: Array,
    categories: Array,
    employees: Array,
    iosAppUrl: String,
    androidAppUrl: String,
    allowNewBusinesses: Boolean
  },
  data() {
    return {
      checkins: [],
      mediaIndex: null,
      mediaSet: null,
      isRequestSent: false,
      filterData: {},
      currentPage: 1,
      perPage: 10,
      totalCount: 0
    }
  },
  computed: {
    filtersAreApplied() {
      return Object.keys(this.filterData).some((filter) => this.filterData[filter].length > 0) || Boolean(this.filterData.scheduled)
    },
    noMoments() {
      return this.checkins.length === 0 && !this.isRequestSent && !this.filtersAreApplied
    }
  },
  created() {
    this.isRequestSent = true
  },
  methods: {
    setFilter(filterData) {
      this.currentPage = 1
      this.filterData = filterData
      this.getCheckins()
    },
    getCheckins() {
      this.isRequestSent = true
      return axios.get(
        BASE_AJAX_URL,
        {
          params: {
            ...this.filterData,
            page: this.currentPage,
            perPage: this.perPage
          },
          paramsSerializer(json) {
            return qs.stringify(snakeCaseKeys(json, { deep: true }), { arrayFormat: 'brackets' })
          },
          ...axiosTransform
        }
      ).then((res) => {
        this.totalCount = parseInt(res.headers['total-count'], 10)
        this.checkins = res.data
      }).finally(() => {
        this.isRequestSent = false
      })
    },
    openPreview({ mediaSet, mediaIndex }) {
      this.mediaSet = mediaSet
      this.mediaIndex = mediaIndex
    },
    closeImage() {
      this.mediaSet = null
      this.mediaIndex = null
    },
    updatePage(page) {
      this.currentPage = page
      this.getCheckins()
    },
    toggleExternalAddMomentButton() {
      document.querySelector('.js-add-moment')?.classList.toggle('d-none', this.noMoments)
    }
  },
  watch: {
    noMoments() {
      this.toggleExternalAddMomentButton()
    }
  }
}
</script>
