<template>
  <div class="review-card">
    <div class="top">
      <div class="business">
        <div>
          <div>
            <i class="fa-regular fa-briefcase mr-1" />
            <a :href="review.linkToOrgloc" target="_blank">{{ review.locationName }}</a>
          </div>
          <div>{{ review.locationAddress }}</div>
        </div>
        <div v-if="review.locationStoreCode">Store Code: {{ review.locationStoreCode }}</div>
      </div>
      <div class="review">
        <div class="reviewer">
          <div v-if="review.reviewer.profilePhotoUrl" class="audit-card-qa__avatar">
            <img :src="review.reviewer.profilePhotoUrl" :alt="`${review.reviewer.displayName} avatar`">
          </div>
          <div>{{ review.reviewer.displayName }}</div>
        </div>
        <div class="review-body">
          <div class="stars-rating">
            <i class="fa-solid fa-star" v-for="i in Array.from({length: review.starRating})" :key="i" />
            <span class="ml-2">{{ review.remoteCreatedAt }}</span>
          </div>
          <div class="review-message">{{ review.comment }}</div>
        </div>
      </div>
    </div>
    <div class="review-response" v-if="review.reviewReply">
      <div class="reply-label">Reply:</div>
      <div class="reply-message">
        <div>{{ review.reviewReply.createdAt }}</div>
        <div class="message-text">{{ review.reviewReply.comment }}</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    review: { type: Object, required: true }
  }
}
</script>
